<template>
    <main class="main">
        <appHeader />
        <appSidebar />
        <router-view :key="$route.path" />   
    </main>
</template>

<script>
export default {
    data(){
        return {}
    },
    created(){},
    components: {
      "appSidebar": () => import("@/components/Sidebar.vue"),
      "appHeader": () => import("@/components/Header.vue"),
    }
}
</script>